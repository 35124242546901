import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import { TemplateOperation, SecteurReferentiel } from '@/models';
import SelectableSecteurList from '@/components/SelectableSecteurList.vue';
import template from './NosOffres.Template.vue';
import { Getter } from 'vuex-class';
import { getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle } from '@/shared/models';
import { Secteurs, Profils } from '@/shared/enums';
import { UserProfile } from '../../store/modules/auth/types';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';

//  Créer une interface pour fusionner les mixins.
interface IMixinInterface extends CommonMixin, Vue { }
@Component({
    ...template,
    name: 'NosOffres',
    components: {
        'cee-secteurs': SelectableSecteurList,
    },
    mixins: [CommonMixin],
})
export default class NosOffres extends VueMixinsDecorator<IMixinInterface>(CommonMixin, Vue) {
    public loading: boolean = false;
    public selectedTemplatesOperations: TemplateOperation[] = new Array<TemplateOperation>();
    public allTemplatesOperations: TemplateOperation[] = new Array<TemplateOperation>();

    /**
     * Le mapping entre un secteur de la BDD et l'affichage
     *
     * @type { SecteurReferentiel[] }
     * @memberof ChoixSecteurEtOperation
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Secteur))
    public secteurs: SecteurReferentiel[];

    /**
     * Le profil utilisateur.
     */
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    /**
     * Secteur à afficher selon le profil.
     */
    public secteursAffiches: SecteurReferentiel[] = [];

    /**
     * Le secteur sélectionné.
     *
     * @type { SecteurReferentiel }
     * @memberof NosOffres
     */
    public selectedSecteur: SecteurReferentiel | null = null;

    /**
     * Dictionnaire des icônes par secteur, à factoriser entre les différentes pages.
     */
    private readonly iconesSecteurs: string[] = [
        'bus',
        'project-diagram',
        'building',
        'industry',
        'tractor',
        'school'
    ];

    /**
     * Constructeur.
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        // Les secteurs affichées doivent être les secteurs standards pour les profils externes. Pour les internes on affiche tout.
        this.getUserProfile.then((profil: UserProfile) => {

            this.getValeursReferentielles(TypeValeurReferentielle.Secteur).then((_: any) => {
                const secteurQueryParam = this.secteurs.find((s: SecteurReferentiel) => s.code === this.$route.query.codeSecteur);
                if (secteurQueryParam) {
                    this.recupererTemplateOperations(secteurQueryParam.code);
                }
                if (profil && profil.profilCode === Profils.SyndicSDC) {
                    const secteursAutorises: Secteurs[] = [Secteurs.Residentiel, Secteurs.Tertiaire];
                    this.secteursAffiches = this.secteurs.filter((s: SecteurReferentiel) => secteursAutorises.map((secteur: string) => secteur).includes(s.code));
                } else {
                    this.secteursAffiches = this.secteurs;
                }
            });
        });
    }

    /**
     * Récupère l'icône en fonction du secteur affiché.
     * @param secteur
     */
    public get iconeSecteur(): string {
        return 'fa fa-' + this.iconesSecteurs[this.selectedSecteur.id - 1];
    }

    /**
     * Événement qui se déclenche lorsque le secteur est sélectionné.
     *
     * @param {string} secteur
     * @memberof ChoixSecteurEtOperation
     */
    public onSelectedSecteur(secteur: SecteurReferentiel) {
        this.recupererTemplateOperations(secteur.code);
    }

    /**
     * Récupérer les template operation par secteur.
     *
     * @param {string} secteur
     * @memberof NosOffres
     */
    public recupererTemplateOperations(secteur: string) {
        this.loading = true;
        const filterCriteria = {
            ...{
                secteur,
                inclureInactif: false,
                inclureInVisibleUtilisateurExterne: true
            },
        };
        this.$http.get(`/templateOperations/listOffres`, { params: filterCriteria })
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: TemplateOperation[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.allTemplatesOperations = responseData;
                } else {
                    this.allTemplatesOperations = new Array<TemplateOperation>();
                }
                this.selectedTemplatesOperations = this.allTemplatesOperations;
            }).finally(() => this.loading = false);
    }
    /**
     * Filtrer les Template Operation.
     *
     * @param {*} event
     * @memberof NosOffres
     */
    public filtre(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement;
        this.selectedTemplatesOperations = this.allTemplatesOperations
            .filter((t) => t.code.toLocaleLowerCase().includes(target.value.toLocaleLowerCase()) ||
                t.libelle.toLocaleLowerCase().includes(target.value.toLocaleLowerCase()));
    }
}
