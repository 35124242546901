import { render, staticRenderFns } from "./NosOffres.Template.vue?vue&type=template&id=20cd30c1&scoped=true&"
var script = {}
import style0 from "./NosOffres.scss?vue&type=style&index=0&id=20cd30c1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cd30c1",
  null
  
)

export default component.exports